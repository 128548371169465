<template>
  <ValidationObserver class="eui-u-h-full" slim ref="observer" v-slot="{ valid }">
    <EuiContainer style="height: calc(100vh - 180px);">
      <AddSignatoriesDialog
        :selected.sync="socialSignatory.authorizedUsers"
        @change="setSignatories"
        @closeDialog="addSignDialog = false"
        :open.sync="addSignDialog"/>
      <div v-if="alertError">
        <EuiAlert color="error" @click:close="alertError = false">
          {{ $t('signworkflows.create.step1.alert.title')}}
          <ul>
            <li v-for="error in errors" :key="error" class="text-white">
              {{ error }}
            </li>
          </ul>
        </EuiAlert>
      </div>
      <EuiGrid v-if="socialSignatory" gutters style="background: #f4f7fd">
        <EuiGridRow>
          <EuiGridColumn>
            <div class="flex items-center">
              <EuiButton @click="cancel" iconOnly size="large" variant="text">
                <EuiIcon color="primary"  name="chevron_left"/>
              </EuiButton>
              <EuiHeading :level="1" bold>{{ $t('signatories.social.edit.title') }}</EuiHeading>
            </div>
          </EuiGridColumn>
        </EuiGridRow>
        <EuiGridRow>
          <EuiGridColumn>
            <EuiBlock :title="$i18n.t('signatories.social.edit.information.title')">
              <EuiGrid>
                <EuiGridRow>
                  <EuiGridColumn>
                    <ValidationProvider :name="$t('signatories.social.create.information.companyName.label')" rules="required" v-slot="{ errors, valid, invalid }">
                      <EuiTextField adaptSize
                                    :label="$t('signatories.social.create.information.companyName.label')"
                                    type="text"
                                    v-model="socialSignatory.companyName"
                                    :valid="valid"
                                    :invalid="errors.length > 0"
                                    :warning="invalid && errors.length === 0"
                                    :errorMessage="errors[0]"/>
                    </ValidationProvider>
                    <ValidationProvider :name="$t('signatories.social.create.information.email.label')" rules="required|email" v-slot="{ errors, valid, invalid }">
                      <EuiTextField adaptSize
                                    :label="$t('signatories.social.create.information.email.label')"
                                    type="email"
                                    v-model="socialSignatory.email"
                                    :valid="valid"
                                    :invalid="errors.length > 0"
                                    :warning="invalid && errors.length === 0"
                                    :errorMessage="errors[0]"/>
                    </ValidationProvider>
                  </EuiGridColumn>
                  <EuiGridColumn direction="row">
                    <EuiTextField :invalid="validationErrors.logoSign">
                      <EuiLabel>{{ $t('signatories.social.edit.information.sign.logo.label') }}</EuiLabel>
                      <input ref="logoSignUploader"
                             style="display: none"
                             type="file"
                             accept="image/png, image/jpeg"
                             @click="resetLogoSignUploader"
                             @change="handleUploadLogoSign($event.target.files[0])">
                      <div class="mt-1">
                        <EuiButton v-if="hasSocialSignerTriggerPermission" @click="$refs.logoSignUploader.click()" variant="raised" size="small" color="primary">
                          {{ $t('button.upload') }}
                        </EuiButton>
                      </div>
                      <div class="es-signatory__logo" v-if="socialSignatory.logoSign && !socialSignatory.logoSign.startsWith('datas/')">
                        <img :src="'data:image/png;base64,' + socialSignatory.logoSign"/>
                        <div v-if="hasSocialSignerTriggerPermission"  class="es-signatory__logo__hover-panel">
                          <EuiIcon name="more_vert"/>
                          <EuiButton size="large" variant="text" @click="socialSignatory.logoSign = undefined"  iconOnly color="primary">
                            <EuiIcon outlined name="delete"/>
                          </EuiButton>
                        </div>
                      </div>
                      <template v-slot:errorMessage>{{ $t('signatories.social.edit.information.logo.error')}}</template>
                    </EuiTextField>
                    <EuiTextField>
                      <EuiLabel>{{ $t('signatories.social.edit.information.paraph.logo.label') }}</EuiLabel>
                      <input ref="logoParaphUploader"
                             style="display: none"
                             type="file"
                             accept="image/png, image/jpeg"
                             @click="resetLogoParaphUploader"
                             @change="handleUploadLogoParaph($event.target.files[0])">
                      <div class="mt-1">
                        <EuiButton v-if="hasSocialSignerTriggerPermission" @click="$refs.logoParaphUploader.click()" variant="raised" size="small" color="primary">
                          {{ $t('button.upload') }}
                        </EuiButton>
                      </div>
                      <div class="es-signatory__logo" v-if="socialSignatory.logoParaph && !socialSignatory.logoParaph.startsWith('datas/')">
                        <img :src="'data:image/png;base64,' + socialSignatory.logoParaph"/>
                        <div v-if="hasSocialSignerTriggerPermission"  class="es-signatory__logo__hover-panel">
                          <EuiIcon name="more_vert"/>
                          <EuiButton size="large" variant="text" @click="socialSignatory.logoParaph = ''"  iconOnly color="primary">
                            <EuiIcon outlined name="delete"/>
                          </EuiButton>
                        </div>
                      </div>
                    </EuiTextField>
                  </EuiGridColumn>
                </EuiGridRow>
              </EuiGrid>
            </EuiBlock>
          </EuiGridColumn>
        </EuiGridRow>
        <EuiGridRow>
          <EuiGridColumn>
            <EuiBlock :title="$i18n.t('signatories.social.edit.users.title')">
              <p v-if="!socialSignatory.authorizedUsers || socialSignatory.authorizedUsers.length === 0" class="mb-4" :class="{'text-alert': validationErrors.authorizedUsers}">
                {{ $t('signatories.social.edit.users.noUsers') }}
              </p>
              <EuiButton class="eui-u-mb-2" v-if="hasSocialSignerTriggerPermission" variant="raised" color="primary" size="small" @click="addSignDialog = true">
                {{ $t('button.add') }}
              </EuiButton>
              <EuiTable v-if="socialSignatory.authorizedUsers && socialSignatory.authorizedUsers.length > 0" class="mb-4">
                <EuiTableHeader>
                  <EuiTableRow>
                    <!-- full name -->
                    <EuiTableHead>{{ $t('signatories.users.index.table.name') }}</EuiTableHead>
                    <EuiTableHead>{{ $t('signatories.users.index.table.group') }}</EuiTableHead>
                    <EuiTableHead dataType="button"/>
                  </EuiTableRow>
                </EuiTableHeader>
                <EuiTableBody>
                  <!-- table row -->
                  <EuiTableRow v-for="(item, index) in socialSignatory.authorizedUsers" :key="`${index}-${item.userId}`">
                    <!-- full name -->
                    <EuiTableCell>{{ item.fullname }}</EuiTableCell>
                    <EuiTableCell>{{ item.group }}</EuiTableCell>
                    <EuiTableCell dataType="button">
                      <EuiButton v-if="hasSocialSignerTriggerPermission" variant="text" color="primary" @click="removeAuthorizedUser(item)">
                        {{ $t('button.delete') }}
                      </EuiButton>
                    </EuiTableCell>
                  </EuiTableRow>
                </EuiTableBody>
              </EuiTable>
            </EuiBlock>
          </EuiGridColumn>
          <EuiGridColumn v-if="hasSocialSignerTriggerPermission">
            <EuiBlock :title="$i18n.t('signatories.social.edit.certificate.title')">
              <div class="flex flex-wrap">
                <div class="flex flex-col flex-1 pr-3">
                  <ValidationProvider :name="$t('signatories.social.create.certificate.password.label')" :rules="shouldUpdatePassword ? 'required' : ''" v-slot="{ errors, valid, invalid }">
                    <EuiTextField adaptSize
                                  class="mb-2"
                                  :label="$t('signatories.social.create.certificate.password.label')"
                                  :type="passwordFieldType"
                                  v-model="socialSignatory.password"
                                  :valid="valid && validatePassword"
                                  :invalid="errors.length > 0 || !validatePassword"
                                  :warning="invalid && errors.length === 0"
                                  :errorMessage="errors[0]"/>
                  </ValidationProvider>
                  <ValidationProvider :name="$t('signatories.social.create.certificate.passwordConfirmation.label')" :rules="shouldUpdatePassword ? 'required' : ''" v-slot="{ errors, valid, invalid }">
                    <EuiTextField adaptSize
                                  class="mb-2"
                                  :label="$t('signatories.social.create.certificate.passwordConfirmation.label')"
                                  :type="passwordFieldType"
                                  v-model="socialSignatory.confirmPassword"
                                  :valid="valid && validatePassword"
                                  :invalid="errors.length > 0 || !validatePassword"
                                  :warning="invalid && errors.length === 0"
                                  :errorMessage="errors[0]"/>
                  </ValidationProvider>
                  <div>
                    <EuiButton variant="text" color="primary" size="small" @click.prevent="switchPasswordVisibility">
                      <template v-if="passwordFieldType === 'password'">
                        {{ $t('settings.users.create.password.show') }}
                      </template>
                      <template v-else>
                        {{ $t('settings.users.create.password.hide') }}
                      </template>
                    </EuiButton>
                  </div>
                </div>
                <div class="flex flex-col  flex-1">
                  <EuiTextField :invalid="validationErrors.uploadCertificate">
                    <EuiLabel>{{ $t('signatories.social.edit.certificate.upload.label') }}</EuiLabel>
                    <input ref="certificateUploader"
                           style="display: none"
                           type="file"
                           accept="application/x-pkcs12"
                           multiple=false
                           @click="resetCertificateUploader"
                           @change="handleUploadCertificate($event.target.files[0])">
                    <template v-slot:errorMessage>{{ $t('signatories.social.edit.certificate.upload.error') }}</template>
                    <div class="mt-1">
                      <EuiButton @click="$refs.certificateUploader.click()" variant="raised" size="small" color="primary">
                        {{ $t('button.upload') }}
                      </EuiButton>
                    </div>
                  </EuiTextField>
                  <div class="pl-3 flex" v-if="socialSignatory.uploadCertificate">
                    <p>{{ socialSignatory.uploadCertificate.name }}</p><EuiButton iconOnly size="small" @click="socialSignatory.uploadCertificate = undefined"><EuiIcon name="close" /></EuiButton>
                  </div>
                </div>
              </div>
            </EuiBlock>
          </EuiGridColumn>
        </EuiGridRow>
      </EuiGrid>
    </EuiContainer>
    <EuiBottomBar :class="bottomBarStyle">
      <EuiButton variant="text" color="primary" @click="cancel">{{ $t('button.cancel') }}</EuiButton>
      <EuiButton variant="raised" color="primary" @click="update" :disabled="((!valid && !socialSignatory.logo && !socialSignatory.uploadCertificate) || !validatePassword) || haveSameProperties">{{ $t('button.save') }}</EuiButton>
    </EuiBottomBar>
  </ValidationObserver>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import AddSignatoriesDialog from './AddSignatoriesDialog';
import isEqual from 'lodash.isequal';
import DOMpurify from 'dompurify';

export default {
  name: 'EditSocialSignatory',
  /**
   * The properties that can be used.
   */
  props: {
    /**
     * The user identifier used.
     */
    userId: {
      type: [String, Number],
      required: true,
    },
  },
  components: {
    AddSignatoriesDialog,
  },
  watch: {
    socialSignatory: {
      handler() {
        if (this.errors.length > 0) {
          this.checkForm();
        }
      },
      deep: true,
    },
    userId(value) {
      this.fetchSignatory(value);
    },
  },
  computed: {
    ...mapState({
      isSidenavOpen: (state) => state.ModuleEdocSign.ui.isSidenavOpen,
      isMiniVariant: (state) => state.ModuleEdocSign.ui.isMiniVariant,
      isMobileView: (state) => state.application.responsive.xs,
    }),
    hasSocialSignerTriggerPermission() {
      return this.$store.state.ModuleEdocSign.auth.profile.features && this.$store.state.ModuleEdocSign.auth.profile.features.hasSocialSignerTriggerPermission === '1';
    },
    validatePassword() {
      return this.socialSignatory.confirmPassword === this.socialSignatory.password;
    },
    shouldUpdatePassword() {
      return (this.socialSignatory.confirmPassword !== '' || this.socialSignatory.password !== '');
    },
    bottomBarStyle() {
      return {
        '-mini': !this.isMobileView && this.isSidenavOpen && this.isMiniVariant,
        '-default': this.isSidenavOpen && !this.isMiniVariant,
      };
    },
    haveSameProperties() {
      return isEqual(this.tmpSocialSignatory, this.socialSignatory);
    },
  },
  data() {
    return {
      errors: [],
      validationErrors: {},
      addSignDialog: false,
      alertError: false,
      tmpSocialSignatory: {
        companyName: '',
        email: '',
        logoSign: undefined,
        logoParaph: undefined,
        authorizedUsers: [],
        uploadCertificate: undefined,
        password: '',
        confirmPassword: '',
      },
      socialSignatory: {
        companyName: '',
        email: '',
        logoSign: undefined,
        logoParaph: undefined,
        authorizedUsers: [],
        uploadCertificate: undefined,
        password: '',
        confirmPassword: '',
      },
      passwordFieldType: 'password',
    };
  },
  methods: {
    ...mapActions({
      findById: 'ModuleEdocSign/settingsUsers/findById',
      getExternalSignatories: 'ModuleEdocSign/signatory/getSignatories',
      updateSignatory: 'ModuleEdocSign/signatory/updateSignatory',
      searchUsers: 'ModuleEdocSign/settingsUsers/search',
      searchGroup: 'ModuleEdocSign/settingsGroups/search',
    }),
    removeAuthorizedUser(authorizedUser) {
      this.socialSignatory.authorizedUsers = this.socialSignatory.authorizedUsers.filter((element) => element !== authorizedUser);
    },
    switchPasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    setSignatories(signatories) {
      signatories.concat(this.socialSignatory.authorizedUsers);
      const noDuplicateMap = new Map(signatories.map(user => [user.userId, user]));
      const noDuplicateArray = [...noDuplicateMap.values()];
      this.socialSignatory.authorizedUsers = noDuplicateArray.sort((a, b) => ((`${a.fullname}` > `${b.fullname}`) ? 1 : -1));
    },
    fetchSignatory(id) {
      const socialSignatory = this.findById(id).then((response) => {
        if (response && response.account) {
          this.tmpSocialSignatory = {
            userId: response.account.userId,
            companyName: response.account.lastname,
            email: response.account.email,
            logoSign: response.account.logo,
            logoParaph: response.account.paraphImage,
            authorizedUsers: response.account.authorizedUsers,
            uploadCertificate: undefined,
            password: '',
            confirmPassword: '',
          };
          return {
            userId: response.account.userId,
            companyName: response.account.lastname,
            email: response.account.email,
            logoSign: response.account.logo,
            logoParaph: response.account.paraphImage,
            authorizedUsers: response.account.authorizedUsers,
            uploadCertificate: undefined,
            password: '',
            confirmPassword: '',
          };
        }
      });
      const users = this.searchUsers({
        orders: [{ fieldName: 'fullname', orderBy: 'ASC' }],
        offset: 0,
        limit: 1000,
        params: [{ paramName: 'socialSignerId', paramValue: this.userId }],
      }).then((response) => {
        if (response && response.accounts) {
          return response.accounts;
        }
      });
      const groups = this.searchGroup({ orders: [{ fieldName: 'name', orderBy: 'ASC' }], offset: 0, limit: 1000 }).then((response) => {
        if (response && response.groups) {
          return response.groups;
        }
      });
      const mainsUsers = this.getExternalSignatories({
        orders: [{ fieldName: 'fullname', orderBy: 'ASC' }], offset: 0, limit: 1000, params: [],
      }).then((response) => {
        if (response && response.accounts) {
          return response.accounts.filter((item) => item.isMainUser === '1');
        }
      });

      return Promise.all([socialSignatory, users, groups, mainsUsers]).then((values) => {
        for (let i = 0; i < values[1].length; i += 1) {
          const found = values[2].find(element => element.id === values[1][i].userGroupId);
          if (found) values[1][i].group = found.name;
        }
        for (let i = 0; i < values[0].authorizedUsers.length; i += 1) {
          const internalAuthorizedUser = values[1].find((user) => user.userId === values[0].authorizedUsers[i]);
          if (internalAuthorizedUser) {
            values[0].authorizedUsers.splice(i, 1, internalAuthorizedUser);
          } else {
            const mainAuthorizedUser = values[3].find((user) => user.userId === values[0].authorizedUsers[i]);
            if (mainAuthorizedUser) {
              values[0].authorizedUsers.splice(i, 1, mainAuthorizedUser);
            }
          }
        }
        this.socialSignatory = values[0];
      });
    },
    initValidations() {
      this.validationErrors = {
        companyName: false,
        email: false,
        logoSign: false,
        logoParaph: false,
        authorizedUsers: false,
        uploadCertificate: false,
        password: false,
      };
    },
    cancel() {
      this.$router.push({ name: 'signatories.social.index' });
    },
    checkForm() {
      let isValid = true;
      this.errors = [];
      this.initValidations();
      if (!this.socialSignatory.companyName) {
        isValid = false;
        this.validationErrors.companyName = true;
        this.errors.push(this.$i18n.t('signatories.social.edit.information.companyName.error'));
      }

      if (!this.validEmail(this.socialSignatory.email)) {
        isValid = false;
        this.validationErrors.email = true;
        this.errors.push(this.$i18n.t('signatories.social.edit.information.email.error'));
      }
      if (!this.socialSignatory.logoSign) {
        isValid = false;
        this.validationErrors.logoSign = true;
        this.errors.push(this.$i18n.t('signatories.social.edit.information.logo.error'));
      }

      if (this.socialSignatory.authorizedUsers.length === 0) {
        isValid = false;
        this.validationErrors.authorizedUsers = true;
        this.errors.push(this.$i18n.t('signatories.social.edit.authorizedUsers.error'));
      }
      /*
      if (!this.socialSignatory.uploadCertificate) {
        isValid = false;
        this.validationErrors.uploadCertificate = true;
        this.errors.push(this.$i18n.t('signatories.social.edit.certificate.upload.error'));
      }

      if (this.socialSignatory.password.length === 0) {
        isValid = false;
        this.validationErrors.password = true;
        this.errors.push(this.$i18n.t('signatories.social.edit.certificate.password.error.required'));
      } else */
      if (this.socialSignatory.password !== this.socialSignatory.confirmPassword) {
        isValid = false;
        this.validationErrors.password = true;
        this.errors.push(this.$i18n.t('signatories.social.edit.certificate.password.error.mismatch'));
      }

      this.alertError = !isValid;
      return isValid;
    },
    validEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    transformToSend(socialSignatory) {
      return {
        userId: socialSignatory.userId,
        lastname: DOMpurify.sanitize(socialSignatory.companyName),
        email: socialSignatory.email,
        entityType: 'socialSigner',
        logo: socialSignatory.logoSign,
        paraphImg: socialSignatory.logoParaph,
        certificateContent: socialSignatory.uploadCertificate && socialSignatory.uploadCertificate.fileContent ? socialSignatory.uploadCertificate.fileContent : '',
        clearSignatureCode: socialSignatory.password,
        authorizedUsers: socialSignatory.authorizedUsers.map((value) => value.userId),
      };
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject(reader.result);
      });
    },
    resetLogoSignUploader() {
      this.$refs.logoSignUploader.value = '';
    },
    handleUploadLogoSign(file) {
      this.getBase64(file)
        .then((res) => {
          this.socialSignatory.logoSign = res.split(';')[1].split(',')[1];
        });
    },
    resetLogoParaphUploader() {
      this.$refs.logoParaphUploader.value = '';
    },
    handleUploadLogoParaph(file) {
      this.getBase64(file)
        .then((res) => {
          this.socialSignatory.logoParaph = res.split(';')[1].split(',')[1];
        });
    },
    resetCertificateUploader() {
      this.$refs.certificateUploader.value = '';
    },
    handleUploadCertificate(file) {
      this.socialSignatory.uploadCertificate = file;
      this.getBase64(file)
        .then((res) => {
          const fileContent = res.split(';')[1].split(',')[1];
          this.socialSignatory.uploadCertificate = {
            name: file.name,
            fileContent,
          };
        });
    },
    update() {
      if (this.checkForm()) {
        this.updateSignatory(this.transformToSend(this.socialSignatory));
      } else {
        document.getElementsByClassName('eui-o-layout__main')[0].scrollTop = 0;
      }
    },
  },
  mounted() {
    this.fetchSignatory(this.userId);
  },
};
</script>
